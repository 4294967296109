* {
  --color-primary-100: #382bf0;
  --color-primary-200: #5e43f3;
  --color-primary-300: #7a5af5;
  --color-primary-400: #9171f8;
  --color-primary-500: #a688fa;
  --color-primary-600: #ba9ffb;

  --color-surface-100: #121212;
  --color-surface-200: #282828;
  --color-surface-300: #3f3f3f;
  --color-surface-400: #575757;
  --color-surface-500: #717171;
  --color-surface-600: #8b8b8b;

  --color-surface-mixed-100: #1a1625;
  --color-surface-mixed-200: #2f2b3a;
  --color-surface-mixed-300: #46424f;
  --color-surface-mixed-400: #5e5a66;
  --color-surface-mixed-500: #76737e;
  --color-surface-mixed-600: #908d96;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--color-surface-mixed-100); */
  background: linear-gradient(45deg, #1d17a8, #4d37b7);
  background-repeat: no-repeat;
  height: 100%;
  background-attachment: fixed;
  color: #efefef;
}

select {
  background-color: var(--color-surface-200);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 32px 8px 12px; /* Updated padding to include caret arrow */
  font-size: 16px;
  outline: none;
  width: 100%;
  position: relative;
}

label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
  width: 100%;
}

input {
  background-color: var(--color-surface-200);
  position: relative;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
}

button {
  background-color: var(--color-surface-mixed-500);  color: #000;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-top: 0; margin-bottom: 0;
}

.app-wrapper {
  max-width: 600px;
  margin: 1rem auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.avatar-user,
.avatar-assistant {
  font-size: 12px;
  padding: 4px 7px;
  border-radius: 100px;
  background-color: var(--color-surface-mixed-500);
}
